import "core-js/modules/es.array.push.js";
import { get } from './../api';
export default {
  data() {
    return {
      urls: ['river', 'dialect', 'interact'],
      config: {},
      list: [],
      qIndex: -1,
      success: 0,
      to: '',
      end: false,
      assetsUrl: window.assetsUrl,
      width: '',
      height: ''
    };
  },
  mounted() {
    document.title = '知识互动';
    window.addEventListener('resize', () => {
      this.getWindowInfo();
    });
    this.getWindowInfo();
    this.getHome();
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.getWindowInfo();
    });
  },
  methods: {
    getWindowInfo() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    back() {
      this.$router.push('interact');
    },
    replay() {
      this.end = false;
      this.getHome();
    },
    answer(key) {
      let question = this.list[this.qIndex];
      if (question.select) {
        return false;
      }
      question.result = question.answer == key;
      question.select = key;
      this.list.splice(this.qIndex, 1, question);
      if (this.qIndex < this.list.length - 1) {
        setTimeout(() => {
          this.qIndex++;
        }, 1000);
      } else {
        setTimeout(() => {
          let success = 0;
          this.list.forEach(item => {
            if (item.result) {
              success++;
            }
          });
          this.success = success;
          this.end = true;
        }, 1000);
      }
    },
    toUrl(to) {
      this.to = to;
      setTimeout(() => {
        this.$router.push(to);
      }, 300);
    },
    getHome() {
      get('ask').then(res => {
        console.log(res);
        this.list = res.data;
        this.qIndex = 0;
      });
    }
  }
};